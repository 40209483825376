import "./Newsletter.scss";

const Newsletter = () => {
  return (
    <div className="newsletter-section">
        <div className="container">
            <div className="columns">
                <div className="column is-half newsletter-text">
                    <h3>Sign up for the newsletter</h3>
                    <p>Signup to our weekly newsletter service and stay posted with social activities. We conduct various programs which are for youth development, stay updated with our newsletter.</p>
                </div>
                <div className="column newsletter-form">
                    <div className="field has-addons">
                        <div className="control">
                            <input className="input" type="text" placeholder="Enter Your Email" />
                        </div>
                        <div className="control">
                            <button className="button is-info">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Newsletter;
