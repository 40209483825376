import React from "react";
import "./DonateNowButton.scss";
import { ReactComponent as Donate } from "assets/icons/Donate.svg";

const DonateNowButton = (props) => {
  return (
    <a href="/" className={"donate-now " + props.color}>
      <span className="icon-wrapper">
        <Donate />
      </span>
      <span className="donate-now-text">Donate Now</span>
    </a>
  );
};

export default DonateNowButton;
