import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';



const stripePromise = loadStripe('pk_test_51NuKcKHHMWlVfaJLoCvuEFNB8mAIiypUuhFFPXTFWKQ63XqEXwbMA51jPhtWgUL05x7uFUTfsb10gIYm5kgnYfaT00PAAAIOAi');

const StripeContainer = ({ client }) => {
    const options = {
        clientSecret: client?.client_secret,
    };
    return (
        <Elements stripe={stripePromise} options={options} >
            <PaymentForm />
        </Elements>
    )
}

export default StripeContainer
