import { DonateNowButton } from "components";
import "./Footer.scss";
import Logo from "assets/icons/Logo.png";
import FacebookIcon from "assets/icons/facebook.png";
import TwitterIcon from "assets/icons/twitter.png";
import InstagramIcon from "assets/icons/instagram.png";
import LinkedinIcon from "assets/icons/linkedin.png";
import CharityNavigator from "assets/images/CharityNavigator.png";
import CharityWatch from "assets/images/CharityWatch.png";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Footer = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <div className="footer-section">
        <div className="container">
          <div className="columns">
            <div className="column footer-about is-one-third">
              <div className="footer-logo-wrapper">
                <img alt="" src={Logo} className="footer-logo" />
              </div>

              <p>
                Brooklyn Emerge is a 501c(3) organization working in all the
                areas of social development initiatives through our various
                interventions and thematic areas.
              </p>

              <div className="footer-charity-icons">
                <img src={CharityNavigator} alt="Charity Navigator" />
                <img src={CharityWatch} alt="Charity Watch" />
              </div>
            </div>
            {!isMobile && (
              <>
                <div className="column is-half-mobile">
                  <h4 className="footer-nav-heading">Our Projects</h4>
                  <ul className="footer-nav">
                    <li>
                      <Link to="/education-for-all">Education for all</Link>
                    </li>
                    <li>
                      <Link to="/civic-engagement">Civic Engagement</Link>
                    </li>
                    <li>
                      <Link to="/youth-empowerment">Youth Empowerment</Link>
                    </li>
                  </ul>

                  <h4 className="footer-nav-heading">What We Do?</h4>
                  <ul className="footer-nav">
                    <li>
                      <Link to="/">Our Partners</Link>
                    </li>
                    <li>
                      <Link to="/">Our Partnership</Link>
                    </li>
                    <li>
                      <Link to="/">Our Sister Company</Link>
                    </li>
                  </ul>
                </div>
                <div className="column is-half-mobile">
                  <h4 className="footer-nav-heading">User Links</h4>
                  <ul className="footer-nav">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About us</Link>
                    </li>
                    <li>
                      <Link to="/our-programs">Our Programs</Link>
                    </li>
                    <li>
                      <Link to="/">Brand Identity</Link>
                    </li>
                  </ul>
                </div>
                <div className="column is-half-mobile">
                  <h4 className="footer-nav-heading">Donate Now</h4>
                  <p>
                    Join Hands With Us To Diversify The Underserved Community!
                    <br />
                    Donate Now
                  </p>

                  <DonateNowButton color="blue" />

                  <div className="footer-social">
                    <a href="/" target="_blank">
                      <img alt="" src={FacebookIcon} />
                    </a>
                    <a href="/" target="_blank">
                      <img alt="" src={TwitterIcon} />
                    </a>
                    <a href="/" target="_blank">
                      <img alt="" src={InstagramIcon} />
                    </a>
                    <a href="/" target="_blank">
                      <img alt="" src={LinkedinIcon} />
                    </a>
                  </div>
                </div>
              </>
            )}

            {isMobile && (
              <>
                <div className="column is-half-mobile">
                  <h4 className="footer-nav-heading">Our Projects</h4>
                  <ul className="footer-nav">
                    <li>
                      <a href="/">Education for all</a>
                    </li>
                    <li>
                      <a href="/">Civil Engagement</a>
                    </li>
                    <li>
                      <a href="/">Youth Empowerment</a>
                    </li>
                  </ul>

                  <h4 className="footer-nav-heading">User Links</h4>
                  <ul className="footer-nav">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/">About us</a>
                    </li>
                    <li>
                      <a href="/">Our Programs</a>
                    </li>
                    <li>
                      <a href="/">Brand Identity</a>
                    </li>
                  </ul>

                  <div className="footer-social">
                    <a href="/" target="_blank">
                      <img alt="" src={FacebookIcon} />
                    </a>
                    <a href="/" target="_blank">
                      <img alt="" src={TwitterIcon} />
                    </a>
                    <a href="/" target="_blank">
                      <img alt="" src={InstagramIcon} />
                    </a>
                    <a href="/" target="_blank">
                      <img alt="" src={LinkedinIcon} />
                    </a>
                  </div>
                </div>
                <div className="column is-half-mobile">
                  <h4 className="footer-nav-heading">What We Do?</h4>
                  <ul className="footer-nav">
                    <li>
                      <a href="/">Our Partners</a>
                    </li>
                    <li>
                      <a href="/">Our Partnership</a>
                    </li>
                    <li>
                      <a href="/">Our Sister Company</a>
                    </li>
                  </ul>

                  <h4 className="footer-nav-heading">Donate Now</h4>
                  <p>
                    Join Hands With Us To Diversify The Underserved Community!
                    <br />
                    Donate Now
                  </p>

                  <DonateNowButton color="blue" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copywrite">
          Copyright © 2022 Brooklyn Emerge - All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
