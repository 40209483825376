import "App.scss";
import AppRouter from "router";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";

function App() {
  console.log("v 2.3");

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
