import React from "react";
import "./Home.scss";
const Home = () => {
  return (
    <div className="karma">
      <h3>
        There's a natural law of karma that vindictive people who go out of
        their way to hurt others, will end up broke and alone
      </h3>
    </div>
  );
};

export default Home;
