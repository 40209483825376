import { Header } from "components";
import { Outlet } from "react-router-dom";
import Footer from "sections/Footer";
import Newsletter from "sections/Newsletter";
const index = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Newsletter />
      <Footer />
    </>
  );
};

export default index;
