import { Routes, Route } from "react-router-dom";

import Layout from "Layout";
import {
  AboutUs,
  CivicEngagement,
  CommunityBuildingOrganization,
  DigitalLiteracy,
  DonateNow,
  EducationForAll,
  HalalMealShare,
  HealthCareProgram,
  Home,
  Immigration,
  InterfaithHarmony,
  LowIncomeImmigrants,
  MerchantAssociation,
  MuslimCoalition,
  OurPrograms,
  TranslationServices,
  Volunteer,
  YouthEmpowerment,
} from "views";
import { useEffect } from "react";

const index = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<Home />} />
        {/* <Route
          path="/low-income-immigrants"
          element={<LowIncomeImmigrants />}
        />
        <Route path="/civic-engagement" element={<CivicEngagement />} />
        <Route
          path="/community-building-organization"
          element={<CommunityBuildingOrganization />}
        />
        <Route path="/youth-empowerment" element={<YouthEmpowerment />} />
        <Route path="/health-care-program" element={<HealthCareProgram />} />
        <Route path="/halal-meal-share" element={<HalalMealShare />} />
        <Route path="/muslim-coalition" element={<MuslimCoalition />} />
        <Route path="/immigration" element={<Immigration />} />
        <Route path="/education-for-all" element={<EducationForAll />} />
        <Route path="/digital-literacy" element={<DigitalLiteracy />} />
        <Route path="/interfaith-harmony" element={<InterfaithHarmony />} />
        <Route path="/translation-services" element={<TranslationServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/donate-now" element={<DonateNow />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route
          path="/merchants-association"
          element={<MerchantAssociation />}
        />
        <Route path="/our-programs" element={<OurPrograms />} /> */}
      </Route>
    </Routes>
  );
};

export default index;
