import "./Header.scss";
import Logo from "assets/icons/Logo.png";
import { ReactComponent as HeartIcon } from "assets/icons/Heart.svg";
import { ReactComponent as Location } from "assets/icons/Location.svg";
import { ReactComponent as Mail } from "assets/icons/Mail.svg";
import { ReactComponent as Phone } from "assets/icons/Phone.svg";
import { DonateNowButton } from "components";
import { useState } from 'react';
import { Link } from "react-router-dom";

function Header() {
  const [openNav, setOpenNav] = useState(false);

  function handleNavToggle(e) {
    e.preventDefault();
    setOpenNav(!openNav);
  }

  return (
    <div className="Header">
      <div className="container">
        <div id="topbar">
          <nav className="level">
            <div className="level-item has-text-centered">
              <Link className="topbar-links" to="/volunteer">
                <div className="topbar-links-icons">
                  <HeartIcon />
                </div>
                <div className="topbar-links-content">
                  <p className="topbar-links-title">
                    Become a<br /> Volunteer
                  </p>
                </div>
              </Link>
            </div>
            <div className="level-item has-text-centered">
              <div className="topbar-links" href="#">
                <div className="topbar-links-icons">
                  <Location />
                </div>
                <a className="topbar-links-content" href="https://www.google.fr/maps/place/1001+Newkirk+Ave,+Brooklyn,+NY+11230,+USA/@40.6340268,-73.9695539,17z/data=!3m1!4b1!4m6!3m5!1s0x89c244cccafaaac7:0x22afe7781078bc54!8m2!3d40.6340268!4d-73.966979!16s%2Fg%2F11fhzj8q33" target="_blank">
                  <p className="topbar-links-title">1001 Newkirk Avenue,</p>
                  <p className="topbar-links-text">New York 11230</p>
                </a>
              </div>
            </div>
            <div className="level-item has-text-centered level-item-logo">
              <img src={Logo} alt="" className="logo-main" />
            </div>
            <div className="level-item has-text-centered">
              <div className="topbar-links" href="#">
                <div className="topbar-links-icons">
                  <Mail />
                </div>
                <div className="topbar-links-content">
                  <p className="topbar-links-title">Send Email</p>
                  <a
                    className="topbar-links-text"
                    href="mailto:info@brooklynemerg.org"
                  >
                    info@brooklynemerg.org
                  </a>
                </div>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div className="topbar-links" href="#">
                <div className="topbar-links-icons">
                  <Phone />
                </div>
                <div className="topbar-links-content">
                  <p className="topbar-links-title">Helpline</p>
                  <a
                    className="topbar-links-text"
                    href="tel:9293089191"
                  >
                    9293089191
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <nav
        id="MainNav"
        className="navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a
              href="/"
              role="button"
              className="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={handleNavToggle}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true" style={{ marginTop:"3px" }}></span>
              <span aria-hidden="true" style={{ marginTop:"6px" }}></span>
            </a>
          </div>

          <div className={"navbar-menu " + (openNav ? 'open' : '')}>
            <div className="navbar-start">
              <Link className="navbar-item" to="/">
                Home
              </Link>

              <Link className="navbar-item" to="/">
                What we do
              </Link>

              <Link className="navbar-item" to="/">
                Our Partners
              </Link>

              <Link className="navbar-item" to="/our-programs">
                Our Programs
              </Link>
            </div>

            <div className="navbar-end">
              <Link className="navbar-item" to="/volunteer">
                Volunteer with us
              </Link>

              <a className="navbar-item" href="#">
                Brand Identity
              </a>

              <div className="navbar-item" href="#">
                <DonateNowButton color="red" />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
